<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('ChatbotQuestions')"
    @cancel="onCancel('ChatbotQuestions')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'ChatbotQuestionsEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'ChatbotQuestions',
      form: {},
      delay: null,
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      chatbotAnswers: (state) => state.common.chatbotAnswers.data,
    }),

    pageHeader() {
      return `Редактирование вопроса №${
        this.item.data?.id ? this.item.data.id : ''
      }`;
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Вопросы для чат-бота',
          route: { name: 'ChatbotQuestions' },
        },
        {
          text: this.pageHeader,
        },
      ];
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'questionText',
              label: 'Текст вопроса',
            },
            {
              type: 'text',
              key: 'baseName',
              label: 'База данных',
            },
            {
              type: 'select',
              key: 'answerId',
              label: 'Ответ',
              list: this.chatbotAnswers,
              attributes: {
                clearable: true,
              },
            },
          ],
        },
      ];
    },
  },

  watch: {
    'form.baseName': {
      handler(newValue, oldValue) {
        if (!newValue) return (this.form.answerId = null);

        if (newValue && newValue !== oldValue) {
          clearTimeout(this.delay);

          this.delay = setTimeout(() => {
            this.getCommonList({
              name: 'ChatbotAnswers',
              params: {
                basename: newValue,
              },
            });
          }, 1000);
        }
      },
    },
  },

  created() {
    this.isLoadingPage = true;

    this.getItem({ name: this.controllerName, id: this.id })
      .then(() => {
        this.form = this.lodash.cloneDeep(this.item.data);
        if (this.item.data.baseName) {
          this.getCommonList({
            name: 'ChatbotAnswers',
            params: {
              basename: this.item.data.baseName,
            },
          });
        }
      })
      .finally(() => {
        this.isLoadingPage = false;
      });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
